import authRquest from '../helpers/authorizateRequest'
export default {
    getParams(params) {
        if(!params) return ''
        if(typeof params == 'string') return params

        if(params.length) {
            let _params =''
            params.forEach(param => {
                const t = '/'+this.getParam(param)
                _params += t == '/' ? '': t
            });
            return _params.replace('//','/').replace('/?', '?')
        } else {
            return this.getParam(params)
        }
    },

    getParam(param) {
        if(typeof param == 'string' || typeof param == 'number') return param
        if(param.length) {
            return param.join('/');
        } else {
            const temp= new URLSearchParams(param).toString(); // return param1=something&param2=somethingelse ... 
            return temp? '?'+temp : ''
        }
    },
    

    async get(payload, url, param) {
        // Преобразуем параметры в строку
        if(url ==  'candidates/index') debugger
        
        const paramStr = this.getParams(param); // Для формирования сегментов пути
        const payloadStr = this.getParams(payload); // Для формирования query string
    
        // Объединяем параметры
        const query = paramStr + (payloadStr ? (paramStr.includes('?') ? '&' : '?') + payloadStr.slice(1) : '');
    
        // Устанавливаем конечную точку
        const endpoint = url || DEFAULT_ENDPOINTS.dashboard; 
        const { data } = await authRquest.get(`${endpoint}${query.replaceAll('//','/')}`);
        return data;
        
    },
        
    async put(payload, url, param) {
        param = this.getParams(param)
        const endpoint = url || DEFAULT_ENDPOINTS.dashboard
        const { data } = await authRquest.put( endpoint + param, payload)
        return data
    },
    async post(payload, url, param) {
        param = this.getParams(param)
        
        const endpoint = url || DEFAULT_ENDPOINTS.dashboard
        const { data } = await authRquest.post(endpoint + param, payload )
        return data
    },
    async delete(payload, url, param) {
        param = this.getParams(param)
        
        const endpoint = url || DEFAULT_ENDPOINTS.dashboard
        const { data } = await authRquest.delete( endpoint + param, payload)
        return data
    },
    
}