<template>
    <div class="uploader">
        <input ref="file" type="file" @change="onFileChange" :disabled="disabled" :multiple="multiple" v-show="false" />
        <v-custom-input
            class="size_large uploader__input"
            type="text"
            v-model="textValue"
            :disabled="disabled"
            :placeholder="placeholder"
            :label="label"
            buttonIcon="#icon-close"
            @click="handleFileClick"
        />
    </div>
</template>

<script>
import vCustomInput from '@components/global/CustomInput'
import vButton from '@components/global/vButton'

export default {
    name: 'uploader',
    components: {
        vCustomInput,
        vButton
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: false 
        },
        value: {
            type: FileList,
            default: () => []
        }
    },
    data() {
        return {
            textValue: '',
        }
    },
    watch:{
        value: function(newVal, oldVal) {
            const files = this.multiple ? newVal : [newVal[0]];
            this.textValue = this.multiple 
                ? Array.from(files).map(file => file.name).join(', ') 
                : files[0].name;
        }
    },
    methods: {
        onFileChange(e) {
            const files = this.multiple ? e.target.files : [e.target.files[0]];
            this.textValue = this.multiple 
                ? Array.from(files).map(file => file.name).join(', ') 
                : files[0].name;
            this.$emit('input', files); // Если multiple, передаем массив файлов
            console.log(files);
        },

        handleFileClick() {
            this.$refs.file.click()
        },
        clear() {
            this.textValue = ''
        },

        removeFileByName(fileName) {
            // Удаляем файл по имени из массива файлов
            const fileArray = Array.from(this.$refs.file.files); // Преобразуем FileList в массив
            const updatedFiles = fileArray.filter(file => file.name !== fileName); // Фильтруем по имени

            // Обновляем список файлов
            this.$refs.file.files = this.createFileList(updatedFiles);
            
            // Обновляем значение отображаемого текста
            this.textValue = updatedFiles.map(file => file.name).join(', ');

            // Emit обновлённый список файлов
            this.$emit('input', this.$refs.file.files);
        },
        createFileList(files) {
            // Вспомогательная функция для создания нового FileList из массива файлов
            const dataTransfer = new DataTransfer();
            files.forEach(file => dataTransfer.items.add(file));
            return dataTransfer.files;
        }
    }
}
</script>

<style lang="scss">

</style>