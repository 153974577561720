
import req from './request'

const DEFAULT_ENDPOINTS = {
    list: '/drivers/default/list',
    functions: '/drivers/default/available-functions',

    // TODO: Удалить ситарые ссылки и методы
    
    search: '/drivers/base',
    save: '/drivers/base',
    baseview: '/drivers/base',
    paymentslist:'/drivers/base/payments',
    nonEditableFields: '/drivers/base/non-editable-fields',
    transactions: '/drivers/base/transactions',
    config: '/drivers/base/list-config',
    summary: '/drivers/base/summary',

    managementUpdate: '/drivers/management/update',
    managementView: '/drivers/management/view',
    managementGetList: '/drivers/management/index',
    managementSessions: '/drivers/management/sessions',
    managementEndSession: '/drivers/management/end-session',
    frozenFunds: '/drivers/frozen-funds',
    cards: '/drivers/base/cards',
    availableBalance:'/drivers/base/available-balance',
    
    // TODO: сделать отдельный car
    searchCar: '/car/search',
    car: '/car',
    carbinding: '/car/binding', 
    carmodels: '/car/models',
    
    card: '/cards/base',
    temporaryCar:'/car/temporary-car-data',

    history: '/drivers/base/change-history',
    driverOrders: '/drivers/base/orders',
    paymentLog: '/drivers/base/payment-log',
    statements: '/drivers/base/statements',
    autoPayment:'/drivers/auto-payment',
    topUp:'/drivers/base/top-up',
    referral: '/drivers/base/referrals',
    tabs: '/drivers/base/tabs',
    calculate: '/payments/base/calculate',
    callhistory: '/drivers/base/call-history',
    coupons: '/drivers/base/coupons',
    
    
    workruleList:'/work-rule/list',
    workrule:'/work-rule',
    orders:'/orders',

    teamSearch: '/drivers/team-search',
    teamSave: '/teams/driver',

    comments: '/drivers/comments/',
    pinFl: '/drivers/pin-fl',

    blackList: '/drivers/black-list',
    checkCall: '/drivers/check-call',
    assetCall: 'drivers/asset-call',

    changeNumber: '/drivers/change-number',
    offer: '/drivers/offer',

    offerSend: '/drivers/offer/send',

    thermalBag: '/drivers/thermal-bag',
    thermalBagReference: '/drivers/thermal-bag/reference',

    promo: '/drivers/promo',
    codes: '/drivers/promo/codes',
    typesActive: '/drivers/promo/types-active',
    typesInActive: '/drivers/promo/types-inactive',

    promoCodesActive: '/drivers/promo-codes/active',
    promoCodesNew: '/drivers/promo-codes/new',
    transfer: '/drivers/base/transfer',
    bonusMovementsHistory: '/drivers/base/bonus-movements-history',
    paymentMonitoringIncome: '/drivers/base/payment-monitoring-income',
    activePaymentRequest: '/drivers/base/active-payment-request',
    getBlockSum: '/drivers/base/get-block-sum/',
    payoutList: '/drivers/base/payout-list',
    balanceHistory: '/drivers/base/balance-history',

    moderationList: '/orders/moderation/list',
    moderationSummary: '/orders/moderation/summary'
}

export default {
    availableBalance(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.availableBalance, param)
    },
    driversWorkRule(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.driversWorkRule, param)
    },

    summary(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.summary, param)
    },
    coupons(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.coupons, param)
    },
    temporaryCar(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.temporaryCar, param)
    },
    orders(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.orders, param)
    },
    callhistory(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.callhistory, param)
    },
    workrule(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.workrule, param)
    },
    workruleList(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.workruleList, param)
    },
    calculate(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.calculate, param)
    },
    paymentLog(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.paymentLog, param)
    },
    autoPayment(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.autoPayment, param)
    },
    referral(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.referral, param)
    },
    topUp(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.topUp, param)
    },
    tabs(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.tabs, param)
    },
    // functions(payload,url) {
    //     return this.post(payload, url || DEFAULT_ENDPOINTS.functions) /// ???
    // },
    driverOrders(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.driverOrders, param)
    },
    statements(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.statements, param)
    },

    history(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.history, param)
    },

    search(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.search, param)
    },
    searchCar(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.searchCar, param)
    },
    save(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.save, param)
    },
    car(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.car, param)
    },
    carbinding(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.carbinding, param)
    },
    carmodels(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.carmodels, param)
    },
    paymentslist(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.paymentslist, param)
    },
    baseview(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.baseview, param)
    },
    nonEditableFields(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.nonEditableFields, param)
    },
    transactions(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.transactions, param)
    },
    managementUpdate(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.managementUpdate, param)
    },
    managementView(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.managementView, param)
    },
    managementGetList(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.managementGetList, param)
    },
    managementSessions(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.managementSessions, param)
    },
    managementEndSession(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.managementEndSession, param)
    },
    cards(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.cards, param)
    },
    card(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.card, param)
    },
    frozenFunds(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.frozenFunds, param)
    }, 
    config(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.config, param)
    },
    teamSearch(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.teamSearch, param)
    },
    teamSave(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.teamSave, param)
    },
    comments(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.comments, param)
    },
    pinFl(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.pinFl, param)
    },
    blackList(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.blackList, param)
    },
    checkCall(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.checkCall, param)
    },
    assetCall(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.assetCall, param)
    },
    changeNumber(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.changeNumber, param)
    },
    offer(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.offer, param)
    },
    offerSend(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.offerSend, param)
    },

    thermalBag(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.thermalBag, param)
    },

    thermalBagReference(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.thermalBagReference, param)
    },

    promo(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.promo, param)
    },
    
    codes(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.codes, param)
    },

    typesActive(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.typesActive, param)
    },
    typesInActive(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.typesInActive, param)
    },
    promoCodesActive(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.promoCodesActive, param)
    },
    promoCodesNew(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.promoCodesNew, param)
    },
    transfer(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.transfer, param)
    },
    bonusMovementsHistory(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.bonusMovementsHistory, param)
    },
    paymentMonitoringIncome(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.paymentMonitoringIncome, param)
    },
    activePaymentRequest(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.activePaymentRequest, param)
    },
    getBlockSum(payload, param, method) {
       return req[method || 'get'](payload, DEFAULT_ENDPOINTS.getBlockSum, param)
    },
    payoutList(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.payoutList, param)
    },
    balanceHistory(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.balanceHistory, param)
    },
    moderationList(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.moderationList, param)
    },
    moderationSummary(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.moderationSummary, param)
    },
}

