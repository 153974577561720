<template>
    <v-top-bar-slot @hide="$emit('hide',false)">
            <p class="title" slot="header"> Быстрые выплаты </p>
            
            <template slot="body">
                
                <div class="topbar-content__row" v-if="!selected">
                    <div class="topbar-content__col">
                        <v-driver-search
                            :style="{ maxWidth: '356px' }"
                            class="size_large"
                            icon="#icon-search"
                            placeholder="Введите"
                            label="Поиск водителя"
                            buttonIcon="icon-loading"
                            @select="HandlerSelectDriver($event)"
                        />
                    </div>
                </div>
                <template v-if="selectedDriverDetail">
                    <template v-if="selectedDriverDetailStatus!=null">
                        <div class="topbar-content__row" v-if="selectedDriverDetailStatus.can_withdraw">
                            <div class="topbar-content__col">
                                <div class="controls__item"><svg width="24" height="24"><use href="#icon-warning-cercle"></use></svg><span>Водитель заблокирован</span></div>
                            </div>
                        </div>

                        <div class="topbar-content__row">
                            <div class="topbar-content__col">
                                <span class="user-info__balance" :class="{
                                    'user-info__balance__color__red': +selectedDriverDetailStatus.total_sum_withdraw.replaceAll(' ','')<=500,
                                    'user-info__balance__color__green': +selectedDriverDetailStatus.total_sum_withdraw.replaceAll(' ','')>500
                                }">
                                    Доступный баланс {{ selectedDriverDetailStatus.total_sum_withdraw }} 
                                </span>
                            </div>
                        </div>
                    </template>
                    <div class="topbar-content__row" v-else>
                        <div class="topbar-content__col">
                            <v-preloader  size="ex-small"/>
                        </div>
                    </div>

                    <div class="topbar-content__row">
                        <div class="topbar-content__col">
                            <p class="user-info">
                                <span class="user-info__balance" :class="{
                                    'user-info__balance__color__red': +selectedDriverDetail.balance.replaceAll(' ','') <=500,
                                    'user-info__balance__color__green': +selectedDriverDetail.balance.replaceAll(' ','') >500
                                }">
                                    Баланс {{ selectedDriverDetail.balance }}
                                </span>
                                <router-link class="user-info__name" :to="{name: 'DriverDetail', query: { driver_id:selectedDriverDetail.full_name.id, park_id: selectedDriverDetail.full_name.park_id }}">
                                    {{ selectedDriverDetail.full_name.text}}
                                </router-link>
                                
                                {{ selectedDriverDetail.phone}} {{ selectedDriverDetail.park_name}} {{ selectedDriverDetail.work_rule}} {{ selectedDriverDetail.call_sign}}
                            </p>
                        </div>
                    </div>
                    <div class="topbar-content__row">
                        <div class="topbar-content__col">
                            <v-custom-select
                                class="size_large"
                                :list="provider.list"
                                :value="provider.item.id"
                                placeholder="Выберите провайдер"
                                label="Провайдер"
                                :error="error?error.provider ||'' : ''"
                                @update="HandleProviderSelect($event)"
                                :disabled="provider.isload || isFormLoad"
                            />
                        </div>
                        <div class="topbar-content__col" v-if="provider.item.id && provider.item.is_external_service && service.list.length">
                            <v-custom-search-select
                                class="size_large"
                                :list="service.list"
                                v-model="service.item"
                                placeholder="Выберите Услугу"
                                label="Услуги"
                                buttonIcon="#icon-close"
                                @input="HandleServiceSelect($event)"
                                @buttonClick = "HandleClickRemoveService()"
                                :error="error?error.service_id ||'' : ''"
                                :disabled="service.isload || isFormLoad"
                                :buttonIconClass= "{ 'button_color_red': true, 'button_hidden': !this.account.is }"
                            />
                        </div>
                        <div class="topbar-content__col" v-if="retr.list.length && service.item.id">
                            <v-custom-select
                                class="size_large"
                                :list="retr.list"
                                v-model="retr.selected"
                                placeholder="Выберите Шаблон"
                                label="Шаблон"
                                @input="HandleRetrSelect"
                            />
                        </div>
                        <div class="topbar-content__col">
                            
                            <v-custom-input
                                class="size_large"
                                placeholder="Сумма для вывода"
                                label="Сумма"
                                type="number"
                                v-model="amount"
                                :error="error? error.amount || commission.min_sum && commission.available_amount  < commission.min_sum? 'Недостаточно средств на балансе для вывода' : '' : ''"
                                @input="handleAmountChange"
                                :disabled="!provider.item.id"
                                :notes="commission.commission? 'Коммиссия:'+commission.commission : ''"
                                :customError="'Допустимая сумма вывода от ${minValue} до ${maxValue} '"
                                :minValue="commission.min_sum"
                                :maxValue="commission.available_amount"
                            />
                        </div>
                        <div class="topbar-content__col">
                            <v-custom-autocomplete
                                v-if="cardLisit && !this.account.is"
                                class="size_large"
                                placeholder="Не введено"
                                label="Карта для вывода"
                                v-model="driversCard"
                                :mask="{
                                    mask:'9999-9999-9999-9999'
                                }"
                                :list="filteredCardList"
                                :buttonIcon="driversCardIcon"
                                @input="HandleCardInput()"
                                @listButtonClick="removeCard($event)"
                                :error="error?error.number || error.account ||'' : ''"
                                :isload="cardLisitUpdated || isFormLoad"
                                :iconSize="{w:16, h:16}"
                                :inputDisabled="selectedDriverDetailStatus && !selectedDriverDetailStatus.can_assign_new_card"
                            />
                            <v-custom-input
                                v-if="this.account.is"
                                class="size_large"
                                placeholder="Введите реквизиты"
                                label="Реквезиты"
                                v-model="account.value"
                                :error="error? error.account : ''"
                                :mask="{
                                    mask: account.mask
                                }"  
                                :maxlength = account.length
                                :disabled="isFormLoad"
                            />
                        </div>
                        <div class="topbar-content__col" style="max-width: 165px; padding-top: 28px;">
                            <v-button @click="HandleClick" 
                                :style="{ height: '56px' }"
                                class="qpay__btn button button_size_large button_color_black"
                                :disabled="isDisabled"
                            >
                                Выплатить
                            </v-button>
                        </div>
                    </div>
                    <div class="topbar-content__row">
                        <div class="topbar-content__col"  v-if="service.item.id">
                            <v-check-box class="size__large checkbox-label" v-model="is_save_template" label="Сохранить как шаблон"/>
                        </div>
                        <div class="topbar-content__col">
                            <v-check-box class="size__large checkbox-label" v-model="is_scheduled" label="Запланированный"/>
                        </div>
                        <div class="topbar-content__col" >
                            <v-check-box class="size__large checkbox-label" v-model="is_all_sum" @input="handleInput" label="вся сумма"/>
                        </div>
                        <div class="topbar-content__col" v-if="!service.item.id"></div>
                    </div>
                </template>
                
                <v-preloader v-if="isload" size="small"/>
            </template>
    </v-top-bar-slot>
</template>

<script>

import api from '@api'

import vSwitcher from '@components/global/CustomSwitcher'
import vCustomAutocomplete from '@components/global/CustomAutocomplete'
import vButton from '@components/global/vButton'
import vCustomInput from '@components/global/CustomInput'
import vCustomSearchSelect from '@components/global/CustomSearchSelect'
import vCheckBox from '@components/global/CheckBox'

import vPreloader from '@components/global/Preloader.vue'

import { eventBus } from '@eventBus'
import { mapActions } from 'vuex'

import vTopBarSlot from './topBarSlot'
import vDriverSearch from './DriverSearch'
import vCustomSelect from '@components/global/CustomSelect.vue'

export default {
    name: 'SideQuickPay',
    props: {
        selected: {
            type: [Object, Boolean],
            default: () => {}
        }
    },
    components: {
        vTopBarSlot,
        vCustomInput,
        vCustomAutocomplete,
        vSwitcher,
        vDriverSearch,
        vCustomSelect,
        vCustomSearchSelect,
        vButton,
        vCheckBox,
        vPreloader
    },
    data() {
        return {
            isload: false,
            switcher: false,
            isDriverClear: false,
            can: null,
            isFormLoad: false,
            inputDelay: 500,
            timer: {},
            driversList: [],
            isSearchLoadding: null,
            selectedDriverDetail: null,
            selectedDriverDetailStatus: null,
            cardLisit: null,
            cardLisitUpdated: false,
            filteredCardList: null,
            is_scheduled: false,
            is_all_sum: false,
            driversCard: {
                text:''
            },
            error: null,
            is_save_template: false,
            search: {
                id: null,
                text: ''
            },
            retr: {
                list: [],
                selected: null
            },
            amount: '',
            number: '',
            provider: {
                item: {id: null},
                list:[],
                isload: false
            },
            service: {
                item: {},
                list: [],
                isload: false
            },
            commission: {
                time: null,
                available_amount: null,
                balance_after_withdraw: null,
                can_provider_sum: null,
                commission: null,
                min_sum: null,
                max_sum: null,
            },
            account : {
                is: false,
                mask: '',
                length: '',
                value: ''
            },

        }
    },
    watch: {
        selected(newValue, oldValue) {
            this.search = newValue
            if(newValue) {
                this.getDetail()
            } else {
                this.selectedDriverDetail = false
            }
        }
    },
    computed: { 
        driversCardIcon() {
            return this.cardLisit && this.cardLisit.length ? '#icon-arrow-down':''
        },

        isDisabled() {
            return !this.amount ||  this.selectedDriverDetail.is_blocked || this.isFormLoad ||  !this.provider.item.id || (this.commission ? !this.commission.can_provider_sum : false) || (this.account.is ? !this.account.value : !this.driversCard.text )
        }
    },
    mounted() {
        if(this.selected) {
            this.search = this.selected
            this.getDetail()
        }
    },
    methods: {
        ...mapActions([
            'TRIGGER_TOPBAR',
            'RESET_TOPBAR'
        ]),
        HandleDriverClick() {
            this.$router.push()
        },
        HandleClickRemoveService() {
            this.account.mask = ''
            this.account.length = ''
            this.account.is = false
            this.account.value = ''
            this.service.item = { id: null}
        },
        HandleServiceSelect(item) {
            this.getAccount(item.id).then(res=>{
                const {mask, length} = res
                this.account.mask = mask || ''
                this.account.length = length || ''
                this.account.is = true

                const driver_id = this.selectedDriverDetail.id
                const park_id = this.selectedDriverDetail.park.id
                const service_id = this.service.item.id
                
                this.getTemplate({driver_id, park_id, service_id}).then(res=>{
                    this.retr.list = res.response
                })
            })
        },
        HandleRetrSelect(item) {
            const {account, amount} = this.retr.list.find(templ=>templ.id == item)
            this.account.value = account
            this.amount = amount
            this.handleAmountChange()
        },
        
        async handleAmountChange() {
            // Если таймер уже существует, сбрасываем его
            if (this.timer) {
                clearTimeout(this.timer);
            }

            // Возвращаем новый промис с задержкой выполнения
            return new Promise((resolve) => {
                // Устанавливаем таймер на 300 мс
                this.timer = setTimeout(async () => {
                    const driver = this.selectedDriverDetail;
                    const provider = this.provider.item;
                    this.commission.time = new Date().getTime();

                    this.amount = this.amount.replace(' ', '');
                    const amount = parseInt(this.amount) || 0;

                    const total_sum_withdraw = parseInt(this.selectedDriverDetailStatus.total_sum_withdraw.replaceAll(' ', ''));
                    if (this.is_all_sum && amount != total_sum_withdraw) {
                        this.is_all_sum = false;
                    } else if (!this.is_all_sum && amount == total_sum_withdraw) {
                        this.is_all_sum = true;
                    }

                    const payload = {
                        id: driver.id,
                        park: driver.park.id,
                        amount,
                        time: this.commission.time,
                        payment_provider_id: provider.id,
                        service_id: this.service.item.id
                    };

                    // Выполняем calc(payload) и возвращаем результат через resolve
                    resolve(this.calc(payload));
                }, 300); // Задержка в 300 мс
            });
        },

        calc(payload) {
            this.isFormLoad = true
            return api.drivers.calculate(payload,'','post').then(res=>{
                const _res = res.response
                const {available_amount, min_sum, max_sum, balance_after_withdraw, can_provider_sum, commission, time } = _res
                if(this.commission.time == time) {
                    this.commission = {
                        available_amount:available_amount,
                        balance_after_withdraw:balance_after_withdraw,
                        can_provider_sum:can_provider_sum,
                        commission:commission,
                        min_sum: min_sum,
                        max_sum: max_sum,
                        time: null
                    }
                    if(available_amount < max_sum){
                        this.commission.max_sum = null
                    }
                }
                return res
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                //this.isloading = false
                this.isFormLoad = false
            })
        },
        HandleProviderSelect(selectedProvider) {
            
            this.provider.item = selectedProvider;
            if(selectedProvider.is_external_service) {
                this.getServiceList().then(res=>{
                    this.service.list = res.response
                })
            }
        },
        resetForm() {
            this.amount = ''
            this.search =  {
                id: null,
                text: ''
            }
            this.selectedDriverDetail = null
            this.account = {
                is: false,
                mask: '',
                length: '',
                value: ''
            }
            this.service.item = {}
            this.retr.list = []
            this.commission = {    
                time: null,
                available_amount: null,
                balance_after_withdraw: null,
                can_provider_sum: null,
                commission: null,
                min_sum: null,
                max_sum: null,
            }  
            this.error = {}
            this.cardLisit = null
            this.cardLisitUpdated = false
            this.filteredCardList = null
            this.driversCard = { 
                text:''
            }
            this.is_save_template = false
            this.isFormLoad = false
        },
        HandlerSelectDriver(item) {
            this.resetForm()
            this.search = item;
            this.selectedDriverDetail = item
            this.getAddition()
            const selectedDriver = {
                driver_id: item.id,
                park_id: item.park.id
            }
            
            this.apiGetDriverCard(selectedDriver)
            this.getProvider().then(res=>{
                this.updateProvider(res.response)
                this.HandleProviderSelect(this.provider.item)
            })
        },
      
        HandleCardInput() {
            this.filteredCardList = this.cardLisit
        },

        HandleClick() {
            this.error = {}
            this.apiPaymantCreate()
        },

        searchRequest() {
            this.isSearchLoadding = true
            api.drivers.search({q:this.search.text}).then(res=>{
                this.driversList = res
            }).catch(err=>{
                this.$toast.error(err.message || err, {
                    timeout: 5000
                });
            }).finally(()=>{
                this.isSearchLoadding = false
            })
        },

        getServiceList() {
            const payload = {
                service:1,
                id: this.provider.item.id
            }
            this.provider.isload = true
            return api.payments.provider(payload).then(response=>{
                return response
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.provider.isload = false
            })
        },
        getAntiFraudBlocked() {
            const {id, park_id} = this.search
            const param = {
                status: 'blocked',
                criteria: this.selectedDriverDetail.id
            }  
            return api.antifraud.list('',param,'get').then(res=>{
                return !!res.response.data.length
            }).catch(error=>{
                this.$toast.error(error.message)
            })
        },
        getAddition() { 
            return this.getAvalibleBalance().then(res=>{
                const {
                    can_withdraw,
                    total_commission,
                    total_sum_withdraw,
                    can_assign_new_card
                } = res.response
                const ret = {
                    total_sum_withdraw,
                    can_withdraw:!can_withdraw,
                    can_assign_new_card
                }
                this.selectedDriverDetailStatus = ret
                return ret
            })  
        },
        getAvalibleBalance() {
            const {id,park} =this.selectedDriverDetail
            const param ='/'+id+'/'+park.id
            return api.drivers.availableBalance('',param).then(res=>{
                return res
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })
        },
        getDetail() {
            this.isload = true
            api.drivers.search({...this.search, short:true }).then(res=>{
                this.selectedDriverDetail = res.response.data[0]
                this.getAddition()
                this.getProvider().then(res=>{
                    this.updateProvider(res.response)
                    if(this.selected) {
                        this.HandleProviderSelect(this.provider.item)
                    }
                    this.isload = false
                })
            }).catch(err=>{
                this.isload = false
                console.error(err)
                this.$toast.error(err.message || err, {
                    timeout: 5000
                });
            })

            this.apiGetDriverCard({
                driver_id:this.search.id,
                park_id:this.search.park_id,
            })
            
            
        },
        apiGetDriverCard(payload) {
            this.cardLisitUpdated = true 
            const   {driver_id, park_id} = payload
            const param = `/${driver_id}/${park_id}?is_active=true`
            api.drivers.cards('',param).then(res=>{
                this.cardLisit = this.formatCartList(res.response.data)
                this.HandleCardInput()
            }).catch(err=>{
                this.$toast.error(err.message || err, {
                    timeout: 5000
                });
            }).finally(()=>{
                this.cardLisitUpdated = false
            })
        },
        formatCartList(list){
            let ret = []
            list.forEach(element => {
                ret.push({
                    ...element,
                    text:cc_format(''+element.number),
                })
            });

            function cc_format(value) {
                let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
                let matches = v.match(/\d{4,16}/g);
                let match = matches && matches[0] || ''
                let parts = []

                for (let i=0, len=match.length; i<len; i+=4) {
                    parts.push(match.substring(i, i+4))
                }

                if (parts.length) {
                    return parts.join('-')
                } else {
                    return value
                }
            }
            return ret
        },
        fillErrors(error) {
            this.error = {}
            error.forEach(err=>{ 
                this.error[err.field] = err.message
            })
        },
        removeCard(item){
            const params = {
                id:item.id
            }
            if(item.id == this.driversCard.id) {
                this.driversCard = {id: null, text:''}
            }
            api.card.delete({params}).then(res=>{
                if(res.status) {
                    this.$toast.success(res.message)
                } else {
                    this.$toast.error(res.message)
                }
            }).catch(err=>{
                this.$toast.error(err.message)
            }).finally(()=>{
                this.apiGetDriverCard({
                    driver_id:this.selectedDriverDetail.id,
                    park_id:this.selectedDriverDetail.park_id
                })
            })
        },
        apiPaymantCreate() {
            this.isFormLoad = true
            let payload = {
                'type':  this.account.is ? 2 : 1,
                "driver_id": this.selectedDriverDetail.id,
                "park_id": this.selectedDriverDetail.park.id,
                "card_id": this.driversCard.id,
                "amount": this.amount,
                'account': this.account.value || this.driversCard.text,
                'service_id': this.service.item.id,
                'payment_provider_id': this.provider.item.id,
                'is_save_template': this.is_save_template,
            }
            
            let fn = api.payments.create
            
            if(this.is_scheduled) {
                fn = api.payments.scheduled
            } 
            if(this.$store.getters.user.permissions.financial_monitoring_task_pool_create) {
                fn = api.financial.taskPoolWithdrawal
            }

            fn(payload,'', 'post').then(res=>{
                this.$toast.success(res.message)
                this.clear()
                this.RESET_TOPBAR()
            }).catch(res=>{
                if(res.response) this.fillErrors(res.response)
                if(res.message) this.$toast.error(res.message)
                console.error(res)
            }).finally(e=>{
                this.isFormLoad = false
                eventBus.$emit('payUpdate', {
                    driver_id: this.selectedDriverDetail.id,
                    park_id: this.selectedDriverDetail.park.id,
                })
            })
        },
        clear() {
            this.error = {},
            this.search =  {
                id: null,
                text: ''
            },
            this.selectedDriverDetail = null
            this.amount = null
            this.driversCard = {
                text:''
            }
        },
        updateProvider(data) {
            const {list, selected_id} = data
            if(selected_id && selected_id!=0) {
                this.provider.item.id = selected_id
            }
            if(list.length == 1) {
                this.provider.item = list[0]
            }
            this.provider.list = list
            
        },
        getProvider() {
            this.isloading = true
            const payload = {
                provider: 1
            }
            return api.payments.provider(payload).then(response=>{
                return response
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })
        },
        getAccount(id) {
            this.service.isload = true

            return api.payments.retrieveByService('',{id}).then(res=>{
                const length = res.response.phone_length || ''
                let mask = null
                if(res.response.phone_mask) {
                    mask = res.response.phone_mask + ' ' + (res.response.phone_mask_template).replaceAll('#','9') 
                }
                return {mask, length}
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.service.isload = false
            })
        },
        getTemplate(payload) {
            return api.payments.retrieveTemplate('',payload).then(res=>{
                return res
            }).catch(error=>{
                if(error.message) this.$toast.error(error.message)
                console.error(error)
            }).finally(e=>{
                //this.isloading = false
            })
        },

        handleInput() {
            if (!this.is_all_sum) return
            const total_sum_withdraw = this.selectedDriverDetailStatus.total_sum_withdraw
            this.amount = total_sum_withdraw
            this.handleAmountChange()
        }
    },

}
</script>

<style lang="scss">
    .user-info{
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: #919197;
        a{ 
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
        }
        &__balance {
            color:  #F9C43E;
            &__color {
                &__red { 
                    color: #e94a4a
                }
                &__green {
                    color: #30C66C;
                }
            }
        }
        &__name {
            color: #6362E5;
        }
    }
    
    .controls {
        &__list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &__item {
            display: flex;
            align-items: center;
            color: #e94a4a;
            margin-right: 18px;

            span {
                color: #0A0A17;
                font-weight: normal;
                font-size: 16px;
            }

            svg {
                margin-right: 8px;
                fill: currentColor;
            }
        }
    }
</style>