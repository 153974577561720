
import req from './request'

const DEFAULT_ENDPOINTS = {
    total: '/transactions/summary/total',
    payments: '/transactions/summary/payments',
    gas: '/transactions/summary/gas',
    list: '/transactions/payout/list',
    unpaid: '/transactions/payout/unpaid',
    transactionDetail: '/transactions/payout/transaction-detail',
    itemDetail: '/transactions/payout/item-detail',
    summaryUnpaidOrders: '/transactions/payout/summary-unpaid-orders',
    statements:'/transactions/payout/statements',
    close: '/transactions/payout/close',
    view: '/transactions/payout/view',
    antiFraudOrders: '/transactions/payout/anti-fraud-orders'
}

export default {

    total(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.total, param)
    },

    payments(payload, param, method) { 
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.payments, param)
    },

    gas(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.gas, param)
    },

    list(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.list, param)
    },

    unpaid(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.unpaid, param)
    },
    transactionDetail(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.transactionDetail, param)
    },
    itemDetail(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.itemDetail, param)
    },
    summaryUnpaidOrders(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.summaryUnpaidOrders, param)
    },
    statements(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.statements, param)
    },
    close(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.close, param)
    },
    view(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.view, param)
    },
    antiFraudOrders(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.antiFraudOrders, param)
    }
}

